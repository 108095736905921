export const orderingCatalog = {
  new: '-activated_at',
  popular: '-views_per_3days',
  views: '-views',
  date: '-published_at',
};

export const activeStatus = 'active';

export const archiveStatus = 'archive';
export const closeStatus = 'close';
export const inactiveStatus = 'inactive';

export const displayInBlock = {
  contactUs: 'contact_us',
  weInSocials: 'we_in_social',
};

export const eventStatuses = {
  active: activeStatus,
};

export const archivingReason = {
  eventPassed: 'event_passed',
  eventCanceled: 'event_canceled',
  ticketsOver: 'tickets_over',
};

export const eventTypes = {
  free: 'FREE',
  restaurant: 'restaurantAction',
};
