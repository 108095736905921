import { keyframes, useTheme } from '@emotion/react';
import { useMemo } from 'react';
import { FavoriteKinds } from '../../models/favorite';
import { useFavorite } from '../../providers/favorite';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import heartFillImg from '../../public/img/heart_fill.png';
import heartEmptyGreyImg from '../../public/img/heart_empty_grey.png';
import heartEmptyWhiteImg from '../../public/img/heart_empty_white.png';

const like = keyframes`
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.6);
  }

  55% {
    transform: scale(0.9);
  }

  78% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
`;

function FavoriteButton({
  kind,
  id,
  variant,
  size,
  fromSlider,
  actionShip,
}: {
  kind: keyof typeof FavoriteKinds;
  id: number | string;
  variant: 'white' | 'gray' | 'red-outline';
  fromSlider?: boolean;
  actionShip?: boolean;
  size: 'small' | 'medium';
}) {
  const theme = useTheme();
  const favoriteContext = useFavorite();
  const isActive = useMemo(
    () => !!favoriteContext?.getFavId(id, kind),
    [favoriteContext, id, kind]
  );
  function toggle() {
    favoriteContext!.updateFavorite(id, kind);
  }
  if (!favoriteContext) {
    return null;
  }
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        toggle();
      }}
      className='heart-icon'
      css={{
        cursor: 'pointer',
        width: 16,
        height: 16,
        ...(fromSlider ? { width: 22, height: 18 } : {}),
        ...(actionShip ? { width: 22, height: 18 } : {}),
        display: 'block',
        backgroundImage: `url(${heartFillImg.src})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...(size === 'small' ? { width: 15, height: 14 } : {}),
        ...(size === 'medium'
          ? { backgroundSize: 'contain', width: 24, height: 24 }
          : {}),
        [DEVICE_SCREENS_MAX_STRING.md]: {
          ...(size === 'medium' ? { minHeight: 16, minWidth: 18 } : {}),
          ...(fromSlider ? { width: 22, height: 21 } : {}),
          ...(actionShip ? { width: 22, height: 21 } : {}),
        },
        ...(variant === 'gray'
          ? {
              backgroundImage: `url(${heartEmptyGreyImg.src})`,
            }
          : {}),
        ...(variant === 'white'
          ? {
              backgroundImage: `url(${heartEmptyWhiteImg.src})`,
            }
          : {}),
        ...(variant === 'red-outline'
          ? {
              backgroundImage: `url(${heartEmptyGreyImg.src})`,
              position: 'relative',
              ...(size === 'medium' && !isActive
                ? {
                    backgroundImage: `url(${heartEmptyGreyImg.src})`,
                  }
                : {}),
              '&:hover:not(:focus)': {
                opacity: 0.5,
                backgroundSize: '83%',
                backgroundImage: `url(${heartFillImg.src})`,
              },
              '&:focus': {
                '&::before': {
                  content: '""',
                  position: `absolute`,
                  display: `block`,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  margin: `auto`,
                  border: `1px solid #003380`,
                },
              },
            }
          : {}),
        ...(isActive
          ? {
              backgroundImage: `url(${heartFillImg.src})`,
              color: theme.colors.red,
              animation: `${like} 0.7s`,

              '&:hover:not(:focus)': {
                backgroundSize: '100%',
                opacity: 0.8,
              },
            }
          : {}),
      }}
    />
  );
}

export default FavoriteButton;
