import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

export default function FavoriteButtonCardWrapper({
  children,
  compilationCard,
}: {
  children: ReactNode;
  compilationCard?: boolean;
}) {
  const theme = useTheme();
  return (
    <div
      title='Добавить в избранное'
      css={{
        position: 'absolute',
        top: 30,
        zIndex: 1,
        paddingTop: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
        fontSize: 13,
        fontWeight: 600,
        color: theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 12,
        left: 'auto',
        bottom: 'auto',
        borderRadius: '50%',
        width: 32,
        height: 32,
        ...(compilationCard
          ? {
              left: 422,
              [DEVICE_SCREENS_MAX_STRING.xl]: {
                top: 24,
                right: 12,
                left: 'auto',
                bottom: 'auto',
              },
            }
          : {}),
      }}
    >
      {children}
    </div>
  );
}
