import NextImage from 'next/image';
import { Interpolation, Theme } from '@emotion/react';
import {
  ImageProp,
  ImageSources,
  Image as ImageType,
} from '../../models/image';
import { PersonImage } from '../../models/person';
import FillPng from '../../public/img/fill.png';

export function Image({
  image,
  is360,
  isCropped,
  className,
  loading = 'lazy',
  alt,
  ...restProps
}: {
  image: ImageType | ImageProp | ImageSources | PersonImage | string;
  is360?: boolean;
  isCropped?: boolean;
  className?: string;
  loading?: 'eager' | 'lazy';
  alt?: string;
}) {
  const propImage =
    typeof image === 'string' ? { image } : (image as ImageSources);
  const {
    image: src,
    image_360: image360,
    cropped_image: croppedImage,
    png_360: png360,
  } = propImage ?? {};

  let jp2 = '';
  let webp = '';

  if (is360) {
    jp2 = propImage?.jp2_360 || propImage?.jp2 || '';
    webp = propImage?.webp_360 || propImage?.webp || '';
  } else {
    jp2 = propImage?.jp2 || '';
    webp = propImage?.webp || '';
  }

  if (!src) {
    return null;
  }

  return (
    <picture {...{ className }}>
      {isCropped && croppedImage && <source srcSet={croppedImage} />}
      {webp && <source srcSet={webp} type='image/webp' />}
      {jp2 && <source srcSet={jp2} type='image/jp2' />}
      {png360 && <source srcSet={png360} type='image/png' />}
      <img
        {...restProps}
        src={
          isCropped && croppedImage
            ? (croppedImage as string)
            : is360 && image360
            ? image360
            : src
        }
        alt={alt || 'изображение'}
        loading={loading}
        {...{ className }}
      />
    </picture>
  );
}

export function Picture(props: {
  src: string | ImageSources;
  alt: string;
  isCropped?: boolean;
  is360?: boolean;
  loading?: 'eager' | 'lazy';
  [key: string]: string | ImageSources | 'eager' | 'lazy' | boolean | undefined;
}) {
  return (
    <div
      // app-picture
      css={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        img: {
          position: 'absolute',
          top: 0,
          margin: 'auto',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
    >
      <Image
        {...props}
        image={props.src}
        alt={props.alt}
        isCropped={props.isCropped || false}
        is360={props.is360 || false}
      />
    </div>
  );
}

export function CustomNextImage({
  image,
  title,
  loading,
  width,
  height,
  css,
}: {
  image: ImageType | ImageProp | PersonImage | string;
  title: string;
  width: string;
  height: string;
  css: Interpolation<Theme>;
  loading?: 'eager' | 'lazy';
}) {
  const {
    image: src,
  }: {
    image: string;
  } = typeof image === 'string' ? { image } : image;
  if (!src) {
    return null;
  }
  return (
    <NextImage
      css={css}
      layout='fill'
      objectFit='contain'
      src={src}
      title={title}
      loading={loading}
    />
  );
}
